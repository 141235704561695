/** @jsx jsx */
import React, { useReducer, useContext, useEffect } from 'react'

import Amplify from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
import { API, graphqlOperation, Cache } from "aws-amplify"
import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex } from "../graphql/shotassetQueries"

import Helmet from 'react-helmet'

import {loadStripe} from '@stripe/stripe-js';
// import {
//   CardElement,
//   Elements,
//   useStripe,
//   useElements,
// } from '@stripe/react-stripe-js';

import { Flex, Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import ProductCard from '../@geocine/gatsby-theme-organization/components/ProductCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../aws-exports";

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../AppsProvider'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}

var stripePromise, checkoutSessionId, uGroups

var setupElements = async function() {
  // stripePromise = await loadStripe("pk_test_8iiUqRzMdP7ipyKMn1wHDhyF");
  stripePromise = await loadStripe("pk_live_xVFKV4FGy3ycA15Ft5vPBZDl");
  
};

async function createCheckoutSession(planId) { 
  let apiName = 'shotwireAPI'; // replace this with your api name.
  let path = '/create-checkout-session'; //replace this with the path you have configured on your API
  let myInit = {
      body: {
        planId: planId
      }, // replace this with attributes you need
      headers: {} // OPTIONAL e.g. "Content-Type": "application/json"
  }

    await API.post(apiName, path, myInit).then(response => {
      // Add your code here
      // console.log('response:',response)
      return response.checkoutSessionId;
    }).then(sessId => {
        // Add your code here
        // console.log('sessId:',sessId)
        checkoutSessionId = sessId;

        stripePromise
          .redirectToCheckout({
            sessionId: sessId
          })
          .then(function(result) {
            // console.log('stripe result:', result)
            // console.log("error");
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          })
          .catch(function(err) {
            // console.log('stripe err:',err);
          });


    }).catch(error => {
        // console.log('sess error:',error)
    });
    // return sess
}


const EnterpriseProductPage = () => {

  let session = null

  const appsContext = React.useContext(AppsContext)

  const handleBuy = (e, planId) => {
      if (e){ 
          e.preventDefault();
        
          // console.log('stripe checkout...')
          // const sessId = stripeSession(planId)
          // console.log('stripe sessId', sessId)
          appsContext.dispatch({type:"CHECKOUT",isCheckoutLoading:true})

          if(appsContext.state.user!==null){
            //this is only for enterprise plans which are not public

            Analytics.record({
                name: 'checkout', 
                // Attribute values must be strings
                attributes: { product: "enterprise video", plan: planId }
            });

            createCheckoutSession(planId).then(sessId => {
                // Add your code here
                // console.log('sessId:',sessId)
                // checkoutSessionId = sessId;
                appsContext.dispatch({type:"CHECKOUT", isCheckoutLoading:false})


            })   

          }else{
            // alert to login required
            appsContext.dispatch({type:"CHECKOUT", isCheckoutLoading:false})
          }

            

      }
  }

    useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up stripe element...')
          setupElements()

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[ ])
    
  useEffect(() => {
		      try{
          
         // console.log('appsContext.state.currentCategory:', appsContext.state.currentCategory)
              // console.log('appsContext.state.categoryFilter:', appsContext.state.categoryFilter)
              // console.log('appsContext.state.categoryMetaField:', appsContext.state.categoryMetaField)
              // getCachedAssetData()
              let isMounted = true
              if(isMounted === true) setupElements();

              if( appsContext.state.isAdmin ){
                // console.log('live data...')
                // getAssetSearchData()
              }
              // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
               //    next: eventData => {
               //      const album = eventData.value.data.onCreateAlbum;
               //      dispatch({ type: "SUBSCRIPTION", album });
               //    }
               //  });
                return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  // appsContext.dispatch({type:"CLEARALBUMS"})
                  isMounted = false
                };
          } catch(error) {
            // console.log('useEffect error:',error)
          }
		}, [ ]);



    const Products = [
        {
          title: "StartUp",
          titleBgColor: "rgba(51, 75, 135, .6)",
          subtitle: "",
          price: "$2,000",
          planId: "plan_HC3nSgTfIhKwAk", //"",  
          public: false,
          description: "Amazon Web Services Deployment;Single AWS Customer Account~;Single Domain;Accept Credit Card Payments with Private Stripe Integration Options;Automated CI/CD;White Label;1yr Tech Support & Upgrades + Annual Renewal Options;Custom SLA Contracts Available",
          terms:"1st installment of $2,000, +3 additional installments"
        },
        {
          title: "Corporate",
          titleBgColor: "rgba(51, 75, 135, .8)",
          subtitle: "",
          price: "$4,500",
          planId: "plan_HC3n7lxZczRxfZ", //"", 
          public: false, 
          description: "Amazon Web Services Deployment;Single AWS Customer Account~;4 Domains Max;Accept Credit Card Payments with Private Stripe Integration Options;Automated CI/CD;White Label;1yr Tech Support & Upgrades + Annual Renewal Options;Custom SLA Contracts Available",
          terms:"1st installment of $4,500, +3 additional installments"
        },
        {
          title: "Enterprise",
          titleBgColor: "rgba(51, 75, 135, 1)",
          subtitle: "",
          price: "$7,000",
          planId: "plan_HC3nIhKVSTzoTy", //"",   
          public: false,     
          description: "Amazon Web Services Deployment;Unlimited AWS Customer Accounts~;Unlimited Domains;Accept Credit Card Payments with Private Stripe Integration Options;Automated CI/CD;White Label;1yr Tech Support & Upgrades + Annual Renewal Options;Custom SLA Contracts Available",
          terms:"1st installment of $7,000, +3 additional installments"
        }
        ]



  return(
  	
<Layout>
  <Helmet>
    <title>Shotwire Video Plans - Enterprise Platform</title>
    <link rel="icon" href={favicon} type="image/x-icon" />
  </Helmet>

 <Container>
 <Row>
 <Col sm={12} lg={4}>
 <h1>Choose a plan.</h1>
 </Col>
 <Col sm={12} lg={4}>
 <h1>Schedule Install.</h1>
 </Col>
 <Col sm={12} lg={4}>
 <h1>Go Live.</h1>
 </Col>
 </Row>
 <Row>
 <Col>
 <h5>Shotwire Enterprise Video</h5>
 </Col>
 </Row>
 <Row>
 <Col sx={{
  // paddingLeft: '10px',
  // padding: '20px 0px 20px',
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '.9rem'

 }}>
 <p>
Shotwire Video Platform for the Enterprise allows organizations to go live in days with full source control, configuration, and deployment on your organization's private Amazon Web Services (AWS) Account. 
 </p>
 <p>
Initial Setup and Deployment of single domain included with extended support contracts available, including custom SLAs.
</p>
<br />
 </Col>
 </Row>
<Row>
        
        { 

          Products.map((product, key) => { 
            const description = product.description.split(';')

            return(

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <ProductCard platform='enterprise' user={appsContext.state.user} assetObj={product} assetId={product.assetId} name={ product.title } titleBgColor={ product.titleBgColor } href="#!" image=''>
                  {product.subtitle}
                  <div style={{fontSize:'1rem', color: colorBlack80}}>                    
                  <ListGroup variant="flush">
                  {
                    description.map((line,key) => (

                        // <li role="listitem" class="">{line}</li>
                        <ListGroup.Item key={key}>{line}</ListGroup.Item>
                     ))                                            
                  }
                  </ListGroup>
                  </div>
                  <div sx={{ color: colorBlack80}}>
                  <span style={{fontSize:'1.3rem'}}>{(appsContext.state.user!==null)?product.price:"Log In For Pricing & Payment Options"}</span>
                  </div>
                  <div>
                  <Button 
                    id="playButton" 
                    // variant="info" 
                    onClick={e => handleBuy(e, product.planId)}
                    disabled={(appsContext.state.user!==null) ? false:!product.public}
                    sx={{backgroundColor:"rgba(89, 130, 235, 1)"}}
                    >   
                   {
                    // (appsContext.state.user!==null) ? <b>BUY NOW TO SCHEDULE INSTALL DATE</b> : <b>LOG IN TO PURCHASE & SCHEDULE INSTALL DATE</b>
                   } 
                   {
                                  (appsContext.state.user!==null) ? 
                                  
                                    (appsContext.state.isCheckoutLoading) 
                                    ? 
                                      
                                        <span> Checkout <FontAwesomeIcon
                                            icon={faSpinner}
                                            size="sm"
                                            sx={{
                                              // marginRight: '0.5rem'
                                            }}
                                            spin
                                          /> 
                                        </span>
                                      
                                      
                                      : <b>BUY NOW TO SCHEDULE INSTALL DATE</b> 
                                      :
                                      <b>LOG IN TO PURCHASE & SCHEDULE SETUP</b> 
                                 
                                  }
                  </Button>
                  <div sx={{ color: colorBlack80}}>
                  {
                    (appsContext.state.user!==null) ? 
                    <span sx={{fontSize:'.8rem'}}><b>Terms:</b> {product.terms}</span> 
                    : null
                   } 
                  
                  </div>
                  </div>
                  </ProductCard>
                </Box>
              )
            }
          )
          
        }

        </Row>
 <Row>
 <Col>
<b><sup>*</sup> Per AWS, Resolutions for both the Basic and Professional tiers are defined as:</b>
<p>
<b>SD</b> is an output of less than 720 resolution.
<br />
<b>HD</b> is an output of greater than or equal to 720 resolution, but less than or equal to 1080 resolution.
<br />
<b>4K</b> is an output of greater than 1080, but less than or equal to 2160 resolution.
<br />
<b>8K</b> is an output of greater than 2160, up to and including 4320 resolution.
</p>
<p>
For outputs with a landscape orientation, resolution is measured vertically.
<br />
For outputs with a portrait orientation, resolution is measured horizontally.
</p>
</Col>
 </Row>

      </Container>
 </Layout>
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default EnterpriseProductPage